.container {
  position: relative;
  margin-left: 16px;
  margin-right: 16px;
  justify-content: center !important;

  @media (min-width: 950px) {
    margin-left: 0;
    margin-right: 0;
  }
}

.container > div > div {
  width: auto !important;
}

.container > div > div > div {
  width: auto !important;
  height: auto !important;
  display: flex !important;
  align-items: flex-end !important;
  justify-content: center !important;
}

.videoContainer {
  z-index: 40;
  min-height: 300px !important;
  margin-top: 12px !important;
  margin-bottom: 12px !important;
  justify-content: center !important;

  @media (min-width: 950px) {
    min-height: 360px !important;
  }
}

.stickyContainer {
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 50;
  width: 100% !important;
  background: #09090b;

  @media (min-width: 950px) {
    background: rgba(9, 9, 11, 0.8);
  }
}

.stickyContainer div > div {
  width: auto !important;
  max-width: fit-content;
}

.stickyContainer div > div > div {
  width: auto !important;
  display: flex !important;
  align-items: flex-end !important;
  justify-content: center !important;
  height: fit-content !important;
  margin-right: 12px;
  margin-left: 12px;
}

.topNavbar {
  min-height: 300px !important;

  @media (min-width: 950px) {
    max-width: 1120px;
  }
}

.topNavbar a {
  display: block;
  width: 100%;
}

.sidebarTop {
  min-height: 300px !important;
  margin-bottom: 24px !important;
}

.sidebarBot {
  min-height: 600px !important;
  margin-top: 48px !important;
}

.inFeed {
  min-height: 300px !important;
  margin-top: 12px !important;
  margin-bottom: 12px !important;

  @media (min-width: 950px) {
    min-height: 360px !important;
  }
}

.belowPager {
  min-height: 300px !important;
  margin-top: 48px !important;

  @media (min-width: 950px) {
    min-height: 360px !important;
  }
}

.abovePager {
  min-height: 300px !important;
  margin-bottom: 16px !important;

  @media (min-width: 950px) {
    min-height: 360px !important;
  }
}
