@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0 !important;
  margin: 0;
  overscroll-behavior: none;
  scroll-behavior: smooth;
  font-family: Sora, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;

  overflow-x: hidden;
  -webkit-tap-highlight-color: transparent;

  @media (min-width: 950px) {
    overflow-x: visible;
  }
}

html::-webkit-scrollbar-thumb {
  background-color: #d1d5db;
  border-radius: 15px;
}

body::-webkit-scrollbar-thumb {
  background-color: #d1d5db;
  border-radius: 15px;
}

@media screen and (min-width: 720px) {
  html::-webkit-scrollbar {
    width: 10px;
  }

  body::-webkit-scrollbar {
    width: 10px;
  }
}

body {
  background: #09090b; /* bg-gray-950 */
  background-attachment: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;

  @media (min-width: 950px) {
    background: radial-gradient(100% 100% at 50% 0%, #17290b 0%, #09090b 100%);
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  scrollbar-width: thin;
}

@layer components {
  .multipleEllipsis {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .threeLinesEllipsis {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .fiveLinesEllipsis {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .tenLinesEllipsis {
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .fourteenLinesEllipsis {
    display: -webkit-box;
    -webkit-line-clamp: 14;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .embedGradient {
    background: linear-gradient(30deg, #0f3d22, #269753 100%);
  }

  .messageGradient {
    background: rgb(104, 0, 0);
    background: linear-gradient(45deg, rgba(104, 0, 0, 1) 0%, rgba(197, 62, 19, 1) 100%);
  }

  .customScroll::-webkit-scrollbar {
    width: 4px;
  }

  .customScroll::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 15px;
  }

  .customScroll:hover::-webkit-scrollbar-thumb {
    background-color: #e5e7eb;
    border-radius: 15px;
  }

  .customScroll::-moz-scrollbar {
    width: 4px;
  }

  .customScroll::-moz-scrollbar-thumb {
    background-color: transparent;
    border-radius: 15px;
  }

  .customScroll:hover::-moz-scrollbar-thumb {
    background-color: #e5e7eb;
    border-radius: 15px;
  }

  .inputOverride:-webkit-autofill,
  .inputOverride:-webkit-autofill:hover,
  .inputOverride:-webkit-autofill:focus,
  .inputOverride:-webkit-autofill:active {
    box-shadow: 0 0 0 30px #18181b inset;
    -webkit-text-fill-color: white;
  }

  .noScrollbar::-webkit-scrollbar {
    display: none !important;
  }

  .noScrollbar {
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
  }

  .customArrowLeftGradient {
    background: linear-gradient(90deg, #09090b 51.98%, rgba(9, 9, 11, 0) 100%);
  }

  .customArrowRightGradient {
    background: linear-gradient(270deg, #09090b 51.98%, rgba(9, 9, 11, 0) 100%);
  }

  .navbarDesktopGradient {
    background: var(--bg-gradient-desktop, rgba(13, 26, 5, 0.85));
  }

  .navbarMobileGradient {
    background: var(--bg-gradient, rgba(23, 41, 11, 0.7));
  }
}

@layer base {
  h1 {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  h2 {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  h3 {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }

  h4 {
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 600;
  }

  h5 {
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 600;
  }

  h6 {
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 600;
  }

  ul,
  ol {
    list-style: initial;
    padding-left: 1rem;
  }
}

.react-datepicker__calendar-icon {
  fill: #a1a1aa !important;
  width: 35px !important;
  height: 35px !important;
}

.react-datepicker {
  border-radius: 15px !important;
  border: none !important;
  background-color: #52525b !important;
}

.react-datepicker .react-datepicker__today-button {
  background-color: #31c46c;
  border-radius: 10px;
  border-top: none;
  color: #fff;
  margin: 15px;
  padding: 10px;
}

.react-datepicker .react-datepicker__triangle {
  display: none !important;
}

.react-datepicker .react-datepicker__day--outside-month {
  color: #a1a1aa !important;
}

.react-datepicker .react-datepicker__day-names {
  padding-top: 1rem;
}

.react-datepicker .react-datepicker__day-name {
  color: #a1a1aa !important;
  font-size: 12px;
}

.react-datepicker .react-datepicker__time-container {
  width: 100% !important;
}

.react-datepicker .react-datepicker__time-container .react-datepicker__time {
  background-color: #52525b;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.react-datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-list {
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
  color: #fff;
  font-size: 12px;
}

.react-datepicker
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-list
  .react-datepicker__time-list-item:hover {
  background-color: #99e6b8 !important;
  color: #3f3f46;
}

.react-datepicker
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-list
  .react-datepicker__time-list-item--selected {
  background-color: #31c46c !important;
}

.react-datepicker .react-datepicker__time-container .react-datepicker__time-box {
  width: 100% !important;
}

.react-datepicker .react-datepicker__time-container .react-datepicker__header--time {
  border-radius: 15px 15px 0px 0px !important;
  background-color: #52525b;
  border-bottom: none;
}

.react-datepicker .react-datepicker__time-container .react-datepicker__header--time .react-datepicker-time__header {
  color: #fff;
  font-size: 12px;
}

.react-datepicker__month-container {
  background-color: #52525b;
  color: #fff;
  font-size: 1rem;
  border-radius: 15px;
}

.react-datepicker__month-container .react-datepicker__month {
  padding: 0rem 0.3rem 0.3rem 0.3rem;
}

.react-datepicker__month-container .react-datepicker__month-text {
  display: inline-block;
  width: 5rem;
  margin: 0.5rem;
  font-size: 1rem;
  padding: 0.2rem;
}

.react-datepicker__month-container .react-datepicker__header {
  background-color: #52525b;
  border-radius: 15px 15px 0px 0px !important;
  padding: 1rem 0.5rem 0rem 0.5rem;
  border-bottom: none;
}

.react-datepicker__month-container .react-datepicker__day {
  color: #fff;
  font-size: 12px;
}

.react-datepicker__month-container .react-datepicker__day:hover {
  background-color: #99e6b8 !important;
  color: #3f3f46;
}

.react-datepicker__month-container .react-datepicker__day--selected {
  background-color: #31c46c !important;
}

.react-datepicker__month-container .react-datepicker__day--keyboard-selected {
  background-color: #31c46c !important;
}

.react-datepicker__portal > div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .react-datepicker--time-only {
    width: 60%;
  }
}

#SfxPopper > div:last-child > div > div:last-child > div > * {
  > div {
    color: #d4d4d8 !important;
    background-color: #27272a !important;
  }

  &:hover > div {
    color: #09090b !important;
    background-color: #ecf3ff !important;

    > div > svg {
      color: #09090b !important;
    }

    > div > label > span {
      color: #09090b !important;
    }
  }
}

.sc-lxwit0-2.kgVWnH.SfxButton-root {
  background-color: #31c46c !important;
}
